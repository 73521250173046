<template>
<div id="EventList">
<v-container fluid>

<div class="titleDiv px-5">
  <p class="display-1">News & Events</p>
</div>

<article class="event-container">
<!-- upcoming events -->
<div>
    <p class="title mb-2">Upcoming Events</p><hr class="mb-5" width="250px">

    <div class="my-5" height="100%" v-show="GetUpcomingEvents">
    <v-list disabled class="transparent animated fadeIn slower" dark height="100%">
    <v-list-item-group>
    <v-row>
      <v-col cols="12" sm="12" md="4" v-for="event in GetUpcomingEvents" :key="event.eventslug">
        <v-list-item>
            <v-list-item-icon>
            <v-icon class="animated infinite heartBeat" style="color: #FFB74D">mdi-star</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
            <p class="subtitle-1">{{event.title}}</p>
            <v-list-item-subtitle class="caption font-italic" v-text="event.DOE"></v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    </v-list-item-group>
    </v-list>
    </div>

    <div v-show="!GetUpcomingEvents" class="my-5" height="100%">
      <v-row>
          <v-col cols="12" sm="12" md="4" v-for="n in 3" :key="n">
          <v-skeleton-loader class="mx-auto" type="paragraph">
          </v-skeleton-loader>
          </v-col>
      </v-row>
    </div>

</div>
<!-- past news -->
<div>
    <p class="title mb-2">Latest News</p><hr class="mb-5" width="250px">

    <v-row height="100%" class="mt-5" v-show="GetPastEvents">
    <v-col cols="12" sm="12" md="4" class="my-3" v-for="event in GetPastEvents" :key="event.eventslug" height="100%">
    <v-fade-transition>
        <v-card class="elevation-5 grey lighten-3 animated fadeIn slower" height="100%">

          <v-img height="200px" class="animated fadeIn slower" aspect-ratio="1" :src="event.imageUrl">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div class="card-strip elevation-5">
            <v-card-subtitle class="py-3 subtitle"> Date : {{event.DOE}}</v-card-subtitle>
          </div>

          <v-card-text class="subtitle-1 card-outter">
            <p class="py-3">{{event.title}}</p>
          </v-card-text>

          <v-card-actions class="card-actions">
            <v-btn class="action-btn elevation-5" @click="$router.push('/eventoverview/' + event.eventslug)">
              View<v-icon class="ml-2" dark>mdi-chevron-double-right</v-icon>
            </v-btn>
          </v-card-actions>


        </v-card>
    </v-fade-transition>
    </v-col>
    </v-row>

    <div v-show="!GetPastEvents" class="my-5" height="100%">
      <v-row>
          <v-col cols="12" sm="12" md="4" v-for="n in 6" :key="n">
          <v-skeleton-loader class="mx-auto" type="card, list-item, actions">
          </v-skeleton-loader>
          </v-col>
      </v-row>
    </div>

</div>
</article>

</v-container>
</div>
</template>

<script>
export default {

    name : 'EventList',

    metaInfo: {
        title: 'Events',
    },

    created(){window.scrollTo(0, 0)},

    computed : {
      GetPastEvents(){
        let data =  this.$store.getters.GetPastEvents;
        return data != '' ? data : null;
      },
      GetUpcomingEvents(){
        let data =  this.$store.getters.GetUpcomingEvents;
        return data != '' ? data : null;
      }
    },
  
}
</script>

<style scoped>
#EventList{
  padding: 3vw;
  padding-top: 2rem !important;
  color: #eee;
}
.event-container{
  padding-left: 1vw;
  padding-right: 1vw;
}
.titleDiv{
  border-left: 10px solid #eee;
  margin-bottom: 2rem;
}
.card-strip{
  background-color: #1A1043 !important;
}
.card-strip .subtitle{
  color: #ddd !important;
}
.card-outter {
  position: relative;
  padding-bottom: 60px;
  color: #222 !important;
}
.card-actions {
  position: absolute;
  bottom: 0;
  padding: 20px 13px
}
.card-actions .action-btn {
  background-color: #1A1043 !important;
  border: 1px solid #ddd !important;
  color: #ddd !important;
}
@media (max-width: 900px) {
  .event-container{
    padding-left: 1px;
    padding-right: 1px;
  }
}
</style>